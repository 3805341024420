import React from 'react';

export default function TitleBar({ title, subTitle }) {
  return (
  <div style={style}>
      { title }
      {
        subTitle ? 
        <h3>{subTitle}</h3>
        : ''
      }
  </div>);
}


const style = {
    backgroundImage: "url('/titlebg.jpg')",
    paddingTop: 80,
    paddingBottom: 80,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    fontSize: 60,
    color: 'white',
    textShadow: '1px 1px 30px black'
}