import React from 'react';
import { Container } from 'react-bootstrap';
import TitleBar from '../../baseUi/includes/TitleBar';

export default function Err404() {
  return <>
  <TitleBar title={'Not found'} />
  <Container>
      <div className='mt-5'></div>
      <h2>The content you are looking for is not found!</h2>
      <div className='mt-5'></div>
      <p>
      Regal Chemical Company was founded by James O. King in 1970. Mr. King founded Regal on a solid foundation of integrity, customer service and well-tested products research & development products of this platfrom, with a vision to be the market leader. Regal continues to operate today on these same fundamentals and now admixture in Bangladesh. Regal provides quality agronomic solutions and Admixture chemicals.
      </p>
      <div style={{ marginTop: 100}}></div>
  </Container>
  </>;
}
