import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import TitleBar from '../../baseUi/includes/TitleBar';
import SlickSlider from './SlickSlider';
import sliderData from '../../data/homepage-slider.json';

export default function Homepage() {
  return (
      <>
      <TitleBar title={'Concrete admixture make better strength'} subTitle={"Quality of generation"} />
      <Container>
        <SlickSlider data={sliderData}/>
        <div className='mt-2'></div>
        <Row>
            <Col className='mt-2' md={6} sm={12}>
                <div className='card'>
                    <h2>Step for use</h2>
                    <p>
                        Do not mix admixture with dry mortar. It’s use before mixing mortar. Be alert do not use double dose of any mortar or   date over chemicals. Before use please take of hand globs and safety shoe. Please take care water cement ratio when mixing concrete. Does curing perfectly with minimum and maximum curing time maintain.  If known to more details please contact with our technical team.
                    </p>
                    <div>
                        {/* <Button variant='primary'>Learn more</Button> */}
                    </div>
                </div>
            </Col>
            <Col className='mt-2' md={6} sm={12}>
            <div className='card'>
                    <h2>Concrete Safety</h2>
                    <p>
                        Concrete admixture isn’t just about using the right products; it’s also about using them safely for get more strength and damp proofing surface. Our safety tips team for a reminder of how to safely prepare, apply, and store concrete admixture chemicals.
                    </p>
                    <div>
                        {/* <Button variant='primary'>Learn more</Button> */}
                    </div>
                </div>
            </Col>
        </Row>
        <div className='mt-3'></div>
        <Row>
            <Col>
                <div className='card'>
                    <h2>Contact with us</h2>
                    <h3>01934785401 <br/> 01832575090</h3>
                    <div>
                        <Button variant='primary'>regalchemical1970@gmail.com </Button>
                    </div>
                </div>
            </Col>
        </Row>
      </Container>
      </>
  );
}
