import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const links = [
    {
        title: "Products",
        link: "products"
    },
    {
        title: "Contact",
        link: "contact"
    },
    {
        title: "Where to buy",
        link: "where-to-buy"
    },
    {
        title: "About",
        link: "about"
    }
];


export default function Footer() {
  return (
    <div style={{background: '#0079c2', color: 'white', padding: 20, marginTop: 40}}>
        <Container>
            <Row>
                <Col>
                    <img src='/logo.jpg' className='img img-fluid' style={{maxWidth: 170}}/>
                </Col>
                <Col>
                {
                    links.map((val, index) => (
                        <ul className='ul'>
                            <li><Link  className='white' to={val.link}>{val.title}</Link></li>
                        </ul>
                    ))
                }
                </Col>
                <Col>
                
                </Col>
            </Row>
        </Container>
    </div>
  );
}
