import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default class SlickSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };
    return (
      <div className="mt-5">
        <Slider {...settings}>
          {this.props.data.map((val, index) => (
            <div key={index}>
              <Link to={val.url}>
                <div
                  style={{
                    borderRadius: 70,
                    border: "4px solid skyblue",
                    width: 130,
                    height: 130,
                    padding: 20,
                  }}
                  className="d-inline-block overflow-hidden"
                >
                  <img src={val.image} className="img img-fluid" />
                </div>
                <h6>{val.title}</h6>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}