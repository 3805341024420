import React from 'react'
import { Link } from 'react-router-dom'
import TitleBar from '../../baseUi/includes/TitleBar'

export default function Products() {
  return (
    <>
    <TitleBar title={'Our products'} />
    <div className='container' style={{maxWidth: 500, textAlign: 'left', paddingTop: 20}}>
        <p>
            1.Watter Proofer,<br/>
            <Link to={`/products/r-con`}>2.Rcon </Link>,<br/>
            <Link to={`/products/anti-salt-plaster`}>3.AntiSaltplaster</Link>,<br/>
            4.Heat Proofer ,<br/>
            5.Rust Cleaner,<br/>
            6.Anchoring Epoxy,<br/>
            7. Epoxy Flooring,<br/>
            8.Anti Rust,<br/>
            9.  Curing Compund,<br/>
            10.Leak Proofer,<br/>
            11.SBR Latex,<br/>
            12.Micro Concrete,<br/>
            13.Damp Care,<br/>
            14. Plasticizer,<br/>
            15Bentonit Powder<br/>
            16. Fair Face plaster,<br/>
            17.Urgent Fix,<br/>
            18.Micro concrete.<br/>
        </p>
    </div>
    </>
  )
}
