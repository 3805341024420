import React from 'react';
import { Container } from 'react-bootstrap';
import TitleBar from '../../baseUi/includes/TitleBar';

export default function About() {
  return (
      <>
      <TitleBar title={'About'} />
      <Container>
          <div className='mt-5'></div>
          <h2>Regal chemical private limited</h2>
          <div className='mt-5'></div>
          <p>
          Regal Chemical Company was founded by James O. King in 1970. Mr. King founded Regal on a solid foundation of integrity, customer service and well-tested products research & development products of this platfrom, with a vision to be the market leader. Regal continues to operate today on these same fundamentals and now admixture in Bangladesh. Regal provides quality agronomic solutions and Admixture chemicals.
          </p>
          <div style={{ marginTop: 180}}></div>
      </Container>
      </>
  );
}
