import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainLayout from './baseUi/layouts/MainLayout';
import Err404 from './views/404/Err404';
import About from './views/about/About';
import Contact from './views/contact/Contact';
import Homepage from './views/homepage/Homepage';
import Product from './views/product/Product';
import Products from './views/products/Products';

function App() {
  return (
    <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/products' element={<Products />} />
            <Route path='/products/:slug' element={<Product />} />
            <Route path='/*' element={<Err404 />} />
          </Routes>
        </MainLayout>
      </BrowserRouter>
  );
}

export default App;
