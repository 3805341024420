import React from 'react';
import { Container } from 'react-bootstrap';
import TitleBar from '../../baseUi/includes/TitleBar';

export default function Contact() {
  return (
      <>
      <TitleBar title={'Contact'} />
      <Container>
          <div className='mt-5'></div>
          <h2>Head office</h2>
          <p style={{fontSize: 20}}>
            Plot-37/4, Block -A, Len-1 <br/>
            Section-6, Mirpur,Dhaka-1216 <br/>
            Email: regalchemical1970@gmail.com  <br/>
            Number :01934785401, 01832575090
          </p>
          <div style={{ marginTop: 100}}></div>
      </Container>
      </>
  );
}
