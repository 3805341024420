import React from 'react'
import { useParams } from 'react-router-dom'
import products from '../../data/products.json';
import Err404 from '../404/Err404';

export default function Product() {
    const browserParams = useParams();
    const product = products.find(x => x.slug === browserParams.slug);
    if(!product) return <Err404 />
  return (
    <div className='container' style={{textAlign: 'left', maxWidth: 500}}>
      <div className='mt-5'></div>
      <h1>{product.title}</h1>
      <div className='mt-5'></div>
      <div className='text-center'>
        <img src={product.image} className='img-fluid' style={{maxWidth: 200}} />
      </div>
      <div className='mt-5'></div>
      <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: product.description}}/>
      <p>
        {product.uses_area
        ?(
          <p><b>Uses area:</b> <br/>{product.uses_area}</p>
        ):''}
      </p>
      {
        product.benefits ?
          <div>
            <b>Benefits:</b>
            <ul>
              {
                product.benefits.map((x, i) => (
                  <li key={i}>{x}</li>
                ))
              }
            </ul>
          </div>
        : <></>
      }
    </div>

  )
}
