import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../includes/Header';
import { Container } from 'react-bootstrap';
import TitleBar from '../includes/TitleBar';
import Footer from '../includes/Footer';
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

export default function MainLayout({ title, children }) {
  return (
    <div className="App">
      <Header />
        { children }
        <Footer />
    </div>
  );
}
